import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'layouts/MainLayout';
import { SEO, FourOhFour } from 'components';

const IndexPage = ({ data }) => {
  const { contentfulSiteMetadata, contentfulHero } = data;

  return (
    <MainLayout
      socialsData={contentfulSiteMetadata}
      heroData={contentfulHero}
      isFourOhFour={true}
    >
      <SEO />
      <FourOhFour />
    </MainLayout>
  );
};

export const query = graphql`
  query {
    contentfulSiteMetadata {
      instagram
      tikTok
      twitter
      footerText
      buttonText
      disclaimer
    }
  }
`;

export default IndexPage;
